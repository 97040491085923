.theme-selection-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.theme-selection-modal h2 {
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.theme-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.theme-options button {
  background-color: #ff6600;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  font-size: 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.theme-options button:hover {
  background-color: #e55c00;
}