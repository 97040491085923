.animated-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Behind the content */
}

/* Base styles for particles */
.particle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.7;
  animation: floatAnimation ease-in-out infinite, rotateAnimation linear infinite, fadeInOut ease-in-out infinite;
}

/* Different particle shapes and colors */
.particle-0 {
  background-color: rgba(255, 255, 255, 0.8);
}

.particle-1 {
  background-color: rgba(255, 204, 128, 0.8);
  border-radius: 0;
}

.particle-2 {
  background-color: rgba(128, 255, 219, 0.8);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.particle-3 {
  background-color: rgba(255, 128, 170, 0.8);
  border-radius: 50%;
}

/* Animations */
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .particle {
    width: 50%;
    height: 50%;
  }
}
