.calculator-widget {
  max-width: 360px;
  margin: 0 auto;
  padding: 20px;
  background-color: #282c34;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.calculator-display {
  background-color: #000;
  color: #fff;
  font-size: 2.5rem;
  text-align: right;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.2);
}

.calculator-keypad {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.calculator-key {
  font-size: 1.5rem;
  border: none;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  color: #fff;
}

.calculator-key:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
}

.function-key {
  background-color: #a6a6a6;
}

.operator-key {
  background-color: #ff9500;
}

.number-key {
  background-color: #505050;
}

.zero-key {
  grid-column: span 2;
}

.calculator-key:active {
  transform: translateY(0);
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.2);
}
