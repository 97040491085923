.testimonials-container {
  margin-bottom: 10rem !important;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Heading */
.testimonials-heading {
  font-size: 2.8rem;
  margin-bottom: 40px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--text-color);
  /* Ensure heading text color changes */
}

/* Card Wrapper */
.testimonial-card-wrapper {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

/* Card Hover Effect */
.testimonial-card {
  /* background-color: var(--card-background-color); */
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  padding: 30px;
  color: var(--text-color);
  /* Ensure card text color changes */
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.2);
}

/* Avatar Styling */
.testimonial-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
  border: 4px solid var(--accent-color);
}

/* Testimonial Text */
.testimonial-text {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 20px;
  color: var(--text-color);
  /* Ensure testimonial text color changes */
}

/* Testimonial Author */
.testimonial-author {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--text-color);
  /* Ensure author text color changes */
}

/* Navigation Dots */
.testimonial-navigation {
  margin-top: 30px;
}

.navigation-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--text-color);
  /* Ensure dot color changes */
  margin: 0 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navigation-dot.active {
  background-color: var(--accent-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonials-heading {
    font-size: 2.4rem;
  }

  .testimonial-card-wrapper {
    max-width: 90%;
    padding: 15px;
  }

  .testimonial-text {
    font-size: 1.4rem;
  }

  .testimonial-author {
    font-size: 1.2rem;
  }
}