.featured-projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.project-card {
  background-color: let(--secondary-background-color);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-info {
  padding: 20px;
  text-align: center;
}

.project-info h3 {
  margin-bottom: 10px;
  color: let(--accent-color);
}

.project-info p {
  margin-bottom: 15px;
  color: let(--text-color);
}

.technologies {
  margin-bottom: 10px;
}

.tech-tag {
  background-color: let(--accent-color);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
}

.project-link {
  color: let(--accent-color);
  text-decoration: none;
  font-weight: bold;
}