.service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.service-card {
  flex: 1 1 320px;
  max-width: 400px;
  min-height: 100%;
  border: 1px solid var(--primary-color);
  padding: 30px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: var(--card-background-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
}

.service-card h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.service-card p {
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.details-list li {
  font-size: 1rem;
}

.service-card button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
}

@media (max-width: 829px) {
  .service-cards {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .service-card {
    flex: 1 1 100%; /* Cards stack in a single column on smaller screens */
  }

  .service-card h3 {
    font-size: 1.6rem;
  }

  .service-card p {
    font-size: 1rem;
  }

  .details-list li {
    font-size: 0.9rem;
  }

  .service-card button {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {

  .service-card {
    flex:1 1 100%;
    padding: 20px;
  }

  .service-card h3 {
    font-size: 1.4rem;
  }

  .service-card p {
    font-size: 0.9rem;
  }

  .details-list li {
    font-size: 0.8rem;
  }

  .service-card button {
    font-size: 0.8rem;
  }
}
