.custom-card {
  background-color: var(--background-color);
  border: 1px solid var(--accent-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  text-align: center;
}

.custom-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.ribbon-badge {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--accent-color);
  color: white;
  padding: 5px 0;
  text-align: center;
  font-weight: bold;
  border-radius: 8px 8px 0 0;
}

.icon-container {
  margin-bottom: 15px;
}

.custom-card-icon {
  width: 50px;
  height: 50px;
  color: var(--primary-color);
}

.custom-card-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: var(--primary-color);
}

p {
  font-size: 1.1rem;
  color: var(--text-color);
}

.details-list {
  margin-top: 15px;
  padding-left: 0;
  list-style-type: none;
}

.details-list li {
  margin-bottom: 10px;
}

.price {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--accent-color);
}

.cta-button {
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
  background-color: var(--accent-color);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-5px);
}

/* Add responsive styles */
@media (max-width: 768px) {
  .custom-card {
    padding: 15px;
  }

  .cta-button {
    padding: 12px 25px;
    font-size: 1rem;
  }
}