/* id="footer-cta" */

.footer-cta {
  flex-direction: column;
  padding: 15px;
  height: 4rem;
}









