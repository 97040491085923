:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --button-background-color: #007bff;
  --button-text-color: #ffffff;
  --button-border-color: #0056b3;
  --button-hover-background-color: #0056b3;
  --button-hover-text-color: #ffffff;
}

body.dark-mode {
  --background-color: #333333;
  --text-color: #f1f1f1;
  --button-background-color: #555555;
  --button-text-color: #f1f1f1;
  --button-border-color: #777777;
  --button-hover-background-color: #777777;
  --button-hover-text-color: #f1f1f1;
}

/* Button.css */

.button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: 2px solid var(--button-border-color);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.button:hover {
  background-color: var(--button-hover-background-color);
  color: var(--button-hover-text-color);
}

.button:active {
  background-color: var(--button-active-background-color);
  color: var(--button-active-text-color);
}

.button:disabled {
  background-color: var(--button-disabled-background-color);
  color: var(--button-disabled-text-color);
  cursor: not-allowed;
}
