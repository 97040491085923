.section-header {
  padding: 40px 20px;
  text-align: center;
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  transition: background 0.5s ease;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.section-subtitle {
  font-size: 1.2rem;
  margin-top: 0;
}

.section-decoration {
  width: 80px;
  height: 4px;
  background-color: var(--primary-color);
  margin: 0 auto 20px;
  border-radius: 2px;
}

.section-decoration-bottom {
  width: 50px;
  height: 4px;
  background-color: var(--accent-color);
  margin: 20px auto 0;
  border-radius: 2px;
}

@media screen and (max-width: 768px) {
  .section-title {
    font-size: 2rem;
  }

  .section-subtitle {
    font-size: 1rem;
  }
}
