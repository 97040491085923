.discover-how {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 60px); /* Adjust for footer height */
  margin-bottom: 15rem;
}

.discover-hero {
  text-align: center;
  margin-bottom: 2rem;
}

.discover-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.discover-subtitle {
  font-size: 1.2rem;
  color: #666;
}

.innovation-areas {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}

.area {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.area h2 {
  margin-bottom: 0.5rem;
}

.area p {
  color: #555;
}

.cta-section {
  text-align: center;
  margin-top: auto;
}

.cta-button {
  padding: 1rem 2rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
}

@media (min-width: 768px) {
  .innovation-areas {
    flex-direction: row;
    justify-content: space-between;
  }

  .area {
    width: 30%;
  }
}
